<template>
    <div class="set">
        <van-nav-bar :title="$t('Mt4ApiMenu.settings')" />
        <!--<van-nav-bar title="settings" class="title" />-->
        <div class="page member">
            <div class="cblock">
                <van-cell-group>
                    <van-cell to="/newmt4account" is-link center>
                        <van-col span="24" class="user-info-text">
                            <div class="user-nickname">
                                <span>{{ accountInfo.name }}</span>
                            </div>
                            <div class="user-nickname">
                                <span>{{ userinfo.login }} - {{ accountInfo.company }}</span>
                            </div>
                        </van-col>
                    </van-cell>
                </van-cell-group>
            </div>
            <div class="cblock">
                <van-cell-group>
                    <!--<van-cell icon="user-o" to="/newmt4account" :title="$t('Mt4ApiTrade.newAccount')" is-link></van-cell>-->
                    <van-cell icon="balance-pay"
                              to="/loginbypwd"
                              :title="$t('Mt4ApiTrade.toCrm')"
                              is-link></van-cell>
                    <van-cell :icon="langImg"
                              to="/member/language"
                              :title="$t('app.language')"
                              is-link />
                    <van-cell icon="setting-o"
                              to="/chartsettings"
                              :title="$t('Mt4ApiMenu.settings')"
                              is-link></van-cell>
                </van-cell-group>
            </div>
            <div class="cblock margin-top">
                <van-cell-group>
                    <van-cell icon="records"
                              to=""
                              :title="$t('Mt4ApiMenu.agreement')"
                              is-link></van-cell>
                    <van-cell :icon="lockImg"
                              to=""
                              :title="$t('Mt4ApiMenu.privacyPolicy')"
                              is-link></van-cell>
                    <van-cell icon="info-o"
                              to="/about"
                              :title="$t('Mt4ApiMenu.aboutUs')"
                              is-link></van-cell>
                </van-cell-group>
            </div>
            <div class="cblock margin-top">
                <van-cell-group>
                    <van-cell :icon="exitImg"
                              @click="quit"
                              :title="$t('Menu.logOut')"
                              is-link />
                </van-cell-group>
            </div>
        </div>
    </div>
</template>
<script>
    import { LogOut, GetAccountInfo } from "@/api/mt4Api";
    import { Notify, Dialog } from 'vant';
    export default {
        data() {
            return {
                langImg: require("@/assets/image/language.svg"),
                exitImg: require("@/assets/image/exit.svg"),
                lockImg: require("@/assets/image/lock.svg"),
                groupOrder: 0,
                creditOrder: 0,
                allbanlance: 0,
                alldeposit: 0,
                allwithdrawl: 0,
                isLogin: true,
                openShare: true,
                counts: [],
                credit_counts: [],
                userinfo: this.$store.state.mt4userinfo,
                accountInfo: {
                    balance: "",
                    credit: "",
                    equity: "",
                    margin: "",
                    marginFree: "",
                    profit: "",
                    marginLevel: "",
                    company: "",
                    name: "",
                },
                accountinfo: null,
            };
        },
        computed: {},
        mounted() {
            this.$store.commit("toggleTabbarShow", true);
            this.$store.commit("toggleTabbarCurrentShow", "Mt4");

            let info = localStorage.getItem("accountinfo");
            if (info == null) {
                this.loadData();
            } else {
                info = localStorage.getItem("accountinfo");
                this.accountinfo = JSON.parse(info);
                this.loadFromStorage(this.accountinfo);
            }
        },
        beforeDestroy() { },
        methods: {
            loadData() {
                let param = {
                    xxx: "",
                };
                GetAccountInfo(param).then((res) => {
                    if ((res.code = 200)) {
                        let json = res.msg;
                        localStorage.setItem("accountinfo", JSON.stringify(json));
                        this.loadFromStorage(json);
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
            },
            loadFromStorage(json) {
                this.accountInfo.balance = json.balance + "";
                this.accountInfo.credit = json.credit;
                this.accountInfo.equity = json.equity;
                this.accountInfo.margin = json.margin;
                this.accountInfo.marginFree = json.marginfree;
                this.accountInfo.profit = json.profit;
                if (json.margin <= 0) {
                    this.accountInfo.marginLevel = 0;
                } else {
                    this.accountInfo.marginLevel = (json.equity / json.margin) * 100;
                }
                this.accountInfo.company = json.company;
                this.accountInfo.name = json.name;
                this.accountInfo.currency = json.currency;
            },
            quit() {
                var currTab = this.$store.state.tabbarCurrentShow;
                if (!this.$store.state.tokenMt4) {
                    Notify({ type: 'danger', message: this.$t("notLoggenIn") });
                    return ;
                }

                this.$dialog
                    .confirm({
                        message: this.$t("confirmExit"),
                        confirmButtonText: this.$t("ok"),
                        cancelButtonText: this.$t("cancel"),
                    })
                    .then(() => {
                        this.$store.commit("logout");
                        //if (currTab.toLowerCase() == "crm") {
                        //    this.$router.push("/");
                        //}
                        //if (currTab.toLowerCase() == "mt4") {
                        //    LogOut({ xx: "" }).then((res) => {
                        //        this.$router.push("/");
                        //    });
                        //}
                        LogOut({ xx: "" }).then((res) => {
                            this.$router.push("/");
                        });
                    })
                    .catch(() => { });
            },
            sorry() {
                this.$toast(this.$t("developing"));
            },
            onCancel() { },
        },
    };
</script>


<style>
    #app {
        background-color: #f7f7f7;
    }

    .box {
        /*overflow-y: auto;*/
        background-color: #f7f7f7;
    }

    .page {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .cblock {
        margin: 0 10px;
    }

    .user-poster {
        width: 100%;
        height: 70px;
        background: #fff center top no-repeat;
        background-size: cover;
        text-align: center;
        position: relative;
        margin-bottom: 0.2rem;
    }

        .user-poster .right-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            color: #fff;
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        }

    .user-avatar {
        align-items: center;
        margin-right: 10px;
    }

    .user-info-text {
        text-align: center;
        /* color: #fff;*/
        font-size: 14px;
        padding-top: 0.2rem;
    }

    .user-nickname {
        padding-top: 0.1rem;
        font-size: 16px;
    }

    .user-group {
        margin-top: 15px;
    }

    .user-links a {
        color: #444;
    }

    .user-links {
        margin-top: 15px;
        text-align: left;
        background-color: #fff;
    }

        .user-links .van-row {
            padding: 15px 0;
            font-size: 12px;
            text-align: center;
        }

            .user-links .van-row .van-col {
                position: relative;
            }

            .user-links .van-row .van-tag {
                position: absolute;
                left: 60%;
                top: -5px;
            }

            .user-links .van-row .van-icon {
                display: block;
                font-size: 24px;
                color: #af8755;
            }

    .points-box {
        height: 90px;
        margin: 0 10px;
        text-align: center;
    }

        .points-box .van-grid {
            border-radius: 5px;
            margin-top: -35px;
            height: 90px;
            overflow: hidden;
        }

            .points-box .van-grid .balance-value {
                font-size: 12px;
                font-weight: bold;
                margin-top: 4px;
                color: #b00;
            }

            .points-box .van-grid .balance-text .balance-name {
                color: #666;
            }

            .points-box .van-grid .van-grid-item__content {
                padding: 4px;
            }

            .points-box .van-grid .balance-text {
                margin-top: 0;
                font-size: 10px;
            }

    .margin-top {
        margin-top: 0.5rem;
    }

    .cblock:last-child {
        padding-bottom: 0.5rem;
    }

    .set .van-nav-bar {
        background-color: #13b5b1;
    }

    .set .van-nav-bar__title {
        color: #fff;
    }

    .set img {
        vertical-align: baseline;
    }
</style>